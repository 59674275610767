import logo from './logo.svg';
import './App.css';
import { useState } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'
function App() {
  const [address, setAddress] = useState('')
  const [balance, setBalance] = useState(0)
  const [totalEmission, setTotalEmission] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    setAddress(e.target.value)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!ethers.isAddress(address)) {
      alert("Invalid address")
      return
    }

    let url = `https://faucetapi.uns.technology/${address}`;
    const { data } = await axios.get(url)
    console.log(data)
    alert(data.status)


  }

  const getBalance = async () => {
    let url = `https://faucetapi.uns.technology`;
    const { data } = await axios.get(url)
    let bal = Number(data.balance).toFixed(2)
    setBalance(bal)
  }
  getBalance()
  return (
    <div className="container">


      <form>
        <p>Welcome to UNS faucet</p>

        <ul>
          <li >Faucet Balance : {balance} UNS</li>
        </ul>
        <p>Enter your UNS address to receive free UNS</p>
        <input onChange={handleChange} type="text" name="address" />
        <button onClick={handleSubmit} type="submit">Submit</button>
        <p>Don't have a UNS address? Get one for free at <a href="https://metamask.io/">Metamask</a></p>
        <p>Powered by <a href="https://uns.technology">UNS</a></p>
      </form>
    </div>
  );
}

export default App;
